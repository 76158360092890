import * as Action from './Profile.actions';
import { withLoadable } from 'utils/hoc';
import { ProfileActionTypes, ProfileState } from './Profile.interfaces';
import { Reducer } from 'react';
import { getUserInfo, updateUserInfo } from 'utils/helpers';

const initialState: ProfileState = {
  loading: false,
  error: null,
  upcoming: [],
  past: [],
  shareable: [],
  shared: [],
  postedEvent: null,
  tags: [],
  favoriteEvents: [],
  contributors: [],
  producers: [],
  userInfo: null,
  currentInvoicesPage: 1,
  totalInvoices: 0,
  cardHistory: null,
  generalStats: [],
  planStats: [],
  paymentStats: [],
  zoneStats: [],
  producerRedactorStats: null,
  currentUserPage: undefined,
  customerPortal: null,
  oneInvoice: null,
  subscribeToPush: false,
  transcription: undefined,
  company_name: null,
  entityPlan: null,
  eventsPageState: { tab: 'upcoming' },
  myEntity: null,
  newSecrete: null,
};

const reducer: Reducer<ProfileState, ProfileActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_USERS_EVENTS_SUCCESS: {
      return {
        ...state,
        upcoming: action.payload.upcoming,
        past: action.payload.past,
        shareable: action.payload.shareable,
        shared: action.payload.shared,
      };
    }
    case Action.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload.results,
      };
    }

    case Action.LIST_MY_FAVORITES_SUCCESS: {
      return {
        ...state,
        favoriteContributors: action.payload,
      };
    }

    case Action.GET_CONTRIBUTORS_SUCCESS: {
      return {
        ...state,
        contributors: action.payload.results,
      };
    }
    case Action.GET_PRODUCERS_SUCCESS: {
      return {
        ...state,
        producers: action.payload.results,
      };
    }
    case Action.GET_MY_ENTITY_SUCCESS: {
      return {
        ...state,
        myEntity: action.payload,
      };
    }
    case Action.UPDATE_ENTITY_SUCCESS: {
      return {
        ...state,
        myEntity: action.payload,
      };
    }
    case Action.PATCH_UPDATE_ADMIN_INFO_SUCCESS:
    case Action.PATCH_UPDATE_USERINFO_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case Action.PATCH_UPDATE_USER_IMAGE_SUCCESS: {
      const currUser = getUserInfo();
      if (action.payload.id === currUser.id) {
        return {
          ...state,
          userInfo: { ...currUser, ...state.userInfo, ...action.payload },
        };
      } else {
        return { ...state };
      }
    }
    case Action.GET_CARD_HISTORY: {
      return {
        ...state,
        currentInvoicesPage: action.payload.query.page,
      };
    }
    case Action.GET_CARD_HISTORY_SUCCESS: {
      return {
        ...state,
        cardHistory: action.payload.results,
        totalInvoices: action.payload.count,
      };
    }
    case Action.GET_GENERAL_STATS_SUCCESS: {
      return {
        ...state,
        generalStats: action.payload,
      };
    }
    case Action.GET_PLAN_STATS_SUCCESS: {
      return {
        ...state,
        planStats: action.payload,
      };
    }
    case Action.GET_PAYMENT_STATS_SUCCESS: {
      return {
        ...state,
        paymentStats: action.payload,
      };
    }
    case Action.GET_ZONE_STATS_SUCCESS: {
      return {
        ...state,
        zoneStats: action.payload,
      };
    }
    case Action.GET_PRODUCER_REDACTOR_STATS_SUCCESS: {
      return {
        ...state,
        producerRedactorStats: action.payload,
      };
    }
    case Action.CLEAR_PRODUCER_REDACTOR_STATS: {
      return {
        ...state,
        producerRedactorStats: [],
      };
    }
    case Action.CLEAR_CONTRIBUTOR_OPTIONS: {
      return {
        ...state,
        contributors: [],
      };
    }

    case Action.GET_CONTRIBUTORS: {
      return {
        ...state,
        currentUserPage: action.payload.query.page,
      };
    }
    case Action.POST_CUSTOMER_PORTAL_SUCCESS: {
      return {
        ...state,
        customerPortal: action.payload.url,
      };
    }
    case Action.GET_ONE_INVOICE_SUCCESS: {
      return {
        ...state,
        oneInvoice: action.payload,
      };
    }
    case Action.CLEAR_ONE_INVOICE: {
      return {
        ...state,
        oneInvoice: null,
      };
    }
    case Action.SUBSCRIBE_TO_PUSH: {
      return {
        ...state,
        subscribeToPush: true,
      };
    }
    case Action.TRANSCRIBE_AUDIO_SUCCESS: {
      return {
        ...state,
        transcription: action.payload,
      };
    }
    case Action.CLEAR_TRANSCRIPTION: {
      return {
        ...state,
        transcription: action.payload,
      };
    }
    case Action.SET_ACCEPTED_TERMS_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }

    case Action.GET_ENTITY_PLAN_SUCCESS:
    case Action.UPDATE_ENTITY_PLAN_SUCCESS: {
      return {
        ...state,
        entityPlan: action.payload,
      };
    }

    case Action.SET_EVENTS_PAGE_STATE: {
      return {
        ...state,
        eventsPageState: action.payload,
      };
    }

    case Action.PUT_FAVORITE_SUCCESS: {
      const user = getUserInfo();
      let favoriteEvents = [...state.favoriteEvents];
      if (action.payload.status === 'added') {
        user.favorites.push(action.payload.event.id);
        favoriteEvents = [...favoriteEvents, action.payload.event];
      } else {
        user.favorites = user.favorites.filter((fav: number) => fav !== action.payload.event.id);
        favoriteEvents = state.favoriteEvents.filter((f) => f.id !== action.payload.event.id);
      }
      updateUserInfo(user);
      return {
        ...state,
        favoriteEvents: favoriteEvents,
      };
    }
    case Action.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        upcoming: state.upcoming.filter((event) => event.id != action.payload.id),
        past: state.past.filter((event) => event.id != action.payload.id),
      };
    }

    case Action.REGENERATE_SECRET_KEY_SUCCESS: {
      return {
        ...state,
        newSecrete: action.payload,
      };
    }
    default:
      return state;
  }
};

export const profileReducer = withLoadable([
  Action.GET_USERS_EVENTS,
  Action.POST_SET_NEW_PASSWORD,
  Action.GET_TAGS,
  Action.GET_USERS_BY_ID,
  Action.PUT_FAVORITE,
  Action.PATCH_UPDATE_USERINFO,
  Action.GET_CARD_HISTORY,
  Action.GET_GENERAL_STATS,
  Action.GET_PLAN_STATS,
  Action.GET_PAYMENT_STATS,
  Action.GET_ZONE_STATS,
  Action.GET_CONTRIBUTORS,
  Action.GET_ZIPCODES,
  Action.PATCH_UPDATE_ADMIN_INFO,
  Action.PATCH_UPDATE_USER_IMAGE,
  Action.POST_CUSTOMER_PORTAL,
  Action.GET_ONE_INVOICE,
  Action.TRANSCRIBE_AUDIO,
  Action.GET_MY_ENTITY,
  Action.UPDATE_ENTITY,
])(reducer);
